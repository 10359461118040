.portfolio__container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    justify-content: center; /* This centers the items */
}

.portfolio__container p {
    margin: 1rem;
}

.portfolio__item {
    background: transparent;
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid white;
    transition: var(--transition);
    flex: 1 1 calc(33.333% - 2.5rem); /* 3 items per row, accounting for the gap */
    max-width: calc(33.333% - 2.5rem); /* Ensures items don't grow larger than this */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.portfolio__item:hover {
    border-color: transparent;
    background: #2980b9;
}

.portfolio__item h3 {
    text-align: center;
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    transition: var(--transition);
}

.portfolio__item-cta:hover {
    transform: translateY(-5px);
    color: transparent;
}

.btn:hover {
    background-color: white;
}

/*=====================  Media Queries (Medium Screen) ====================*/
@media screen and (max-width: 1024px) {
    .portfolio__item {
        flex: 1 1 calc(50% - 1.2rem); /* 2 items per row */
        max-width: calc(50% - 1.2rem);
    }
}

/*=====================  Media Queries (Small Screen) ====================*/
@media screen and (max-width: 600px) {
    .portfolio__item {
        flex: 1 1 100%; /* 1 item per row */
        max-width: 100%;
    }
}

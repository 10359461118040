.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
    margin-left: 100px;


}

.about__me-image {
    border-radius: 2rem;
    overflow:hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-white);
}

.about__content ul {
    padding-top: 20px;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 10px));
  }
  
  .about__content li {
    position: relative;
    padding-left: 20px;
    font-size: 18px;
    color: var(--slate);
  }
  
  .about__content li::before {
    content: "⇒    ";
    color: #2980b9;
    position: absolute;
    left: 0;
  }

  .btn-primary{
    margin-top: 20px;
    background-color: transparent;
  }

  .btn-primary:hover {
    transform:translateY(-5px);
  }

  .about__content a {
    color: #2980b9;
  }



/*=====================  Media Queries (Medium Screen)   ====================*/
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  
  }

  .about__me {
    width:50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
  
}
  
  /*=====================  Media Queries (Small Screen)   ====================*/
  @media screen and (max-width: 600px) {
    .about__me {
        width:65%;
        margin: 0 auto 3rem;
      }

      .about__content p {
       text-align: center;
      }
    
      .about__content p {
        margin: 1.5rem 0;
      }

      .btn-primary{
    margin-left: 140px;
    justify-content: center;
    margin-top: 2.5rem; 
    display: flex;
      }
  
  
  
    
  }
  
  
  
  
  
    
  
  
  